import styled from "styled-components";
import colors from "assets/styles/color";
import font, { fontWeight } from "assets/styles/fonts";
import { NumberFormat } from "utils/utils";
import { useMemo } from "react";
import AssetPoint from "assets/icons/asset-point-large.webp";

export default function ProductInfo({
  goodsId,
  brand,
  goodsName,
  sellingPrice,
  standardPrice,
  isSoldOut,
  eventPoint,
  onRatingClick,
  discountRate,
}) {
  return (
    <div className="price-card-wrap">
      <input type="hidden" vreview-pdp-product-id={goodsId} />
      <div className="info-box">
        <h1 className={`brand ${isSoldOut ? "isSoldOut" : ""}`}>{brand}</h1>
        <h1 className={`goods-name ${isSoldOut ? "isSoldOut" : ""}`}>
          {goodsName}
        </h1>
        <div
          vreview-product-id={goodsId}
          onClick={(e) => {
            e.stopPropagation();
            onRatingClick();
          }}
          onTouchStart={(e) => {
            e.stopPropagation();
            onRatingClick();
          }}
        ></div>
        {isSoldOut ? (
          <button className="soldout-btn">품절</button>
        ) : (
          <div className="price-wrapper">
            <div className="flex-wrapper">
              <div className="relative-frame">
                <div className="bar" />
                <BeforePrice>
                  {NumberFormat(standardPrice)}
                  <span>원</span>
                </BeforePrice>
              </div>
            </div>
            <div className="flex-wrapper">
              <Discount>{discountRate > 0 ? `${discountRate}%` : ""}</Discount>
              <AfterPrice show={discountRate > 0}>
                {NumberFormat(sellingPrice)}
              </AfterPrice>
              <Won>원</Won>
            </div>
            <DeliveryChargeWrap>
              <DeliveryCharge>무료배송</DeliveryCharge>
            </DeliveryChargeWrap>
          </div>
        )}
      </div>
      {eventPoint !== 0 && (
        <div className="point-box">
          <img className="point-icon" src={AssetPoint} alt="포인트 아이콘" />
          <div className="title">지금 구매하면 포인트 적립</div>
          <div className="point">
            <span className="bold">+{NumberFormat(eventPoint)}</span>
            <span className="medium">pt</span>
          </div>
        </div>
      )}
    </div>
  );
}

const PriceCardWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 0 16px;
  margin-top: 24px;
  margin-bottom: 40px;
`;

const Brand = styled.h1`
  font-size: 14px;
  font-weight: 700;
  color: ${colors.black200};
  opacity: 0.99;
  margin-top: 8px;
  margin-bottom: 4px;
`;

const GoodsName = styled.h1`
  font-size: 18px;
  font-weight: 500;
  color: ${colors.black200};
  line-height: 28px;
`;

const BeforePrice = styled.h2`
  font-size: 14px;
  font-family: "SpoqaHanSansNeo";
  font-weight: 500;
  color: ${colors.black800};

  letter-spacing: 0.28px;

  span {
    font-size: 13px;
    font-weight: 400;
    font-family: "NotoSansKR";
  }
`;

const Discount = styled.h3`
  font-size: 18px;
  font-family: "SpoqaHanSansNeo";
  font-weight: 700;
  color: ${colors.primaryRed};
`;

const AfterPrice = styled.h3`
  font-size: 18px;
  font-family: "SpoqaHanSansNeo";
  font-weight: 700;
  color: ${colors.black300};
  margin-left: ${(props) => (props.show ? "5px" : "")};
`;

const Won = styled.span`
  position: relative;
  top: 1.4px;
  height: 18px;
  font-size: 14px;
  font-weight: 400;
  color: ${colors.black300};
`;

const DeliveryChargeWrap = styled.span`
  margin-top: 15px;
`;
const DeliveryCharge = styled.span`
  width: 48px;
  height: 20px;
  padding: 3px 5px 3px 5px;
  color: #777777;
  font-size: 11px;
  line-height: 25px;
  font-weight: ${fontWeight.regular};
  font-family: ${font.notoSansKR};
  border: 1px solid #d6d6d6;
  border-radius: 5px;
`;

const RelativeFrame = styled.div`
  position: relative;
  margin-bottom: 8px;
`;

const Bar = styled.div`
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: ${colors.black900};
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  div {
    display: flex;
    align-items: center;
  }
`;

const InfoBox = styled.div`
  .isSoldOut {
    color: ${colors.disabledColr};
  }
`;

const FlexWrapper = styled.div`
  ${({ theme }) =>
    theme.flex - wrapper("row", "flex-start", "flex-end")}// margin-bottom: 8px;
`;

const SoldOutBtn = styled.button`
  width: 42px;
  height: 24px;
  border-radius: 4px;
  background-color: ${colors.black400};
  color: white;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 20px;
`;

const PointBox = styled.div`
  background-color: #fff7f1;
  border: 1px solid #fff2e9;
  border-radius: 2px;
  color: ${colors.black300};
  font-size: 14px;
  line-height: 28px;
  letter-spacing: -0.28px;

  width: 100%;
  height: 40px;
  padding: 0 10px;
  margin-top: 20px;
  ${({ theme }) => theme.flexBox("row", "flex-start", "center")}

  .title {
    margin-right: 6px;
  }

  .point {
    font-family: ${font.spoqa};

    .bold {
      font-weight: ${fontWeight.bold};
      letter-spacing: 0.25px;
    }

    .medium {
      font-size: 12px;
      letter-spacing: 0.22px;
    }
  }
`;

const PointIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 3px;
`;
