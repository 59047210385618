import React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { getMainGoodsList } from "services/apis";
import { dateToString, NumberFormat } from "utils/utils";

import { routes } from "routes";
import fonts, { fontWeight } from "assets/styles/fonts";
import colors from "assets/styles/color";
import { S3_BASE_URL } from "utils/constants";
import logoMini from "assets/images/logo-mini.webp";
import timeIcon from "assets/icons/icon-time.webp";
import Timezone from "components/common/Timezone";

const GoodsListBest = ({ goodsData, firstPosition }) => {
  // Best 3를 위로 오도록 변경
  const { goods = [], time = "", goodsGroupName = "" } = goodsData;
  return (
    <GoodsListTopSection firstPosition={firstPosition}>
      <HeaderWrap>
        {/* <HeaderTitleWrap> */}
        {/* <LogoMini src={logoMini} alt="logo" /> */}
        <HeaderTitle>{goodsGroupName}</HeaderTitle>
        {/* </HeaderTitleWrap> */}
        {time !== "" && (
          <Timezone timeData={time} />
          // <TimeWrap>
          //   <TimeIcon src={timeIcon} alt="시간" />
          //   <TimeString>
          //     <span className="spoqa">
          //       {dateToString(time, "YYYY.M.D. HH")}
          //     </span>
          //     시 기준
          //   </TimeString>
          // </TimeWrap>
        )}
      </HeaderWrap>
      <div>
        {goods.map((goods, i) => (
          <Goods key={i.toString()} {...goods} index={i} />
        ))}
      </div>
    </GoodsListTopSection>
  );
};

const GoodsListTopSection = styled.div`
  padding: ${(props) =>
    props.firstPosition ? "0px 16px 64px 16px" : "32px 16px 64px 16px"};
`;

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 30px;
`;

const LogoMini = styled.img`
  width: 54.4px;
  height: 12.4px;
`;

// const HeaderTitleWrap = styled.div`
//   display: flex;
//   flex-direction: column;
// `;

const HeaderTitle = styled.span`
  font-family: NotoSansKR;
  font-weight: bold;
  font-size: 21px;
  letter-spacing: -0.48px;
  color: ${colors.black200};
`;

const TimeWrap = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 2px;
  height: 17px;
`;

const TimeIcon = styled.img`
  width: 11px;
  height: 11px;
  margin-right: 4px;
  margin-bottom: 1px;
  img {
    color: ${colors.black900};
  }
`;

const TimeString = styled.span`
  color: ${colors.black900};
  font-family: ${fonts.notoSansKR};
  font-size: 12px;
  letter-spacing: -0.24px;
  .spoqa {
    font-family: ${fonts.spoqa};
    font-size: 13px;
    letter-spacing: -0.26px;
  }
`;

const Goods = ({
  index,
  goodsId,
  image,
  brand,
  goodsName,
  standardPrice,
  sellingPrice,
  isSoldOut,
  discountRate,
}) => {
  const navigate = useNavigate();

  const handleClickItem = () => {
    navigate(routes.goodsDetail(goodsId));
  };

  return (
    <GoodsWrap onClick={handleClickItem} className="pointer">
      {/* <GoodsIndex>{index + 1}</GoodsIndex> */}
      <GoodsImage src={S3_BASE_URL + "/" + image} isSoldOut={isSoldOut} />
      <GoodsDetail>
        {brand && <GoodsBrand isSoldOut={isSoldOut}>{brand}</GoodsBrand>}
        <GoodsName isSoldOut={isSoldOut}>{goodsName}</GoodsName>
        {isSoldOut ? (
          <SoldOut>품절</SoldOut>
        ) : (
          <>
            <GoodsCostPrice>
              <span className="line-through">
                <span className="spoqa">{NumberFormat(standardPrice)}</span> 원
              </span>
            </GoodsCostPrice>
            <GoodsPriceWrap>
              <DiscountRate show={discountRate > 0}>
                {discountRate > 0 ? `${discountRate}%` : ""}
              </DiscountRate>
              <GoodsPrice>{NumberFormat(sellingPrice)}</GoodsPrice>원
            </GoodsPriceWrap>
            <DeliveryChargeWrap>
              <VReview>
                <div vreview-product-id={goodsId}></div>
              </VReview>
              <DeliveryCharge>무료배송</DeliveryCharge>
            </DeliveryChargeWrap>
          </>
        )}
      </GoodsDetail>
    </GoodsWrap>
  );
};

const GoodsWrap = styled.div`
  display: flex;
  margin-top: 32px;
  &:first-child {
    margin-top: 24px;
  }
`;

const GoodsIndex = styled.span`
  font-family: ${fonts.montserrat};
  font-size: 18px;
  color: ${colors.black200};
  letter-spacing: -0.36px;
  font-weight: 700;
  width: 11px;
`;

const GoodsImage = styled.img`
  width: 36.6%;
  // height: ${window.innerWidth / 3}px;
  /* margin-left: 16px; */
  margin-right: 20px;
  opacity: ${(props) => props.isSoldOut && "0.5"};
  object-fit: contain;
  background-color: ${colors.white700};
  border: 1px solid ${colors.imageBorder};
`;

const GoodsDetail = styled.div`
  display: flex;
  flex-direction: column;
`;

const GoodsBrand = styled.span`
  font-size: 13px;
  color: ${(props) =>
    props.isSoldOut ? colors.disabledColor : colors.black700};
  opacity: 0.99;
  margin-bottom: 6px;
`;

const GoodsName = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: ${(props) =>
    props.isSoldOut ? colors.disabledColor : colors.black400};
`;

const GoodsCostPrice = styled.span`
  margin-top: 10px;
  font-size: 13px;
  color: ${colors.black800};

  .spoqa {
    font-weight: 500;
    font-size: 13px;
  }

  .line-through {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      background-color: ${colors.black800};
      height: 1px;
      top: 50%;
      left: 0;
      right: 0;
    }
  }
`;

const GoodsPriceWrap = styled.span`
  margin-top: 6px;
  font-size: 16px;
  color: ${colors.black300};
`;
const DeliveryChargeWrap = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
`;
const DeliveryCharge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  padding: 3px 5px 3px 5px;
  color: #777777;
  font-size: 11px;
  font-weight: ${fontWeight.regular};
  font-family: ${fonts.notoSansKR};
  border: 1px solid #d6d6d6;
  border-radius: 5px;
`;

const DiscountRate = styled.span`
  font-family: ${fonts.spoqa};
  font-weight: 700;
  font-size: 16px;
  color: ${colors.primaryRed};
  margin-right: ${(props) => (props.show ? "8px" : "")};
`;
const GoodsPrice = styled.span`
  font-family: ${fonts.spoqa};
  font-weight: 700;
  font-size: 16px;
  color: ${colors.black300};
`;

const SoldOut = styled.div`
  margin-top: 13px;
  width: 42px;
  height: 24px;
  background-color: ${colors.black400};
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VReview = styled.div`
  .vreview-review-summary {
    border-radius: 4px;
    border: 1px solid #d6d6d6;
    background: rgba(255, 255, 255, 0);
    padding: 0px 5px;
    width: fit-content;
    height: 20px;
    margin-right: 4px;
    line-height: 20px;
    font-size: 11px;
    font-family: ${fonts.spoqa};
    // color: #777777;
    font-style: normal;
    font-weight: 400;
  }

  .vreview-board-popup {
    margin-top: unset !important;
  }
  .vreview-rating {
    margin-top: -3px !important;
    width: 8px;
    height: 8px;
  }
`;

export default GoodsListBest;
