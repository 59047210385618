import { useState, useEffect } from "react";
import styled from "styled-components";
import colors from "assets/styles/color";
import { getEnvConfig } from "services/apis";
import Error from "assets/icons/icon-warning_two.svg";
import LogoApple from "assets/icons/logo_apple.svg";
import LogoGoogle from "assets/icons/logo_google.svg";
import CancelTwo from "assets/icons/cancel_two.svg";

function OverPriceModal({ modalClose }) {
  const handleToStore = (type) => () => {
    const APP_STORE =
      "https://apps.apple.com/kr/app/%EA%B8%88%EB%B0%A9%EA%B8%88%EB%B0%A9/id1575200660";
    const PLAY_STORE =
      "https://play.google.com/store/apps/details?id=com.korda.goldmarket";

    if (type === "apple") {
      window.open(APP_STORE, "_blank");
    } else {
      window.open(PLAY_STORE, "_blank");
    }
  };

  const [limit, setLimit] = useState(null);
  useEffect(() => {
    getEnvConfig("MALL_DEPOSIT_ONCE").then((res) =>
      setLimit(Math.floor(res?.data?.data?.value / 10000)),
    );
  }, []);

  return (
    <Container>
      <ModalBackdrop onClick={modalClose} />
      <ModalBody>
        <ErrorIcon src={Error} alt="에러 아이콘" />
        <button
          style={{
            position: "absolute",
            right: 16,
            top: 16,
            cursor: "pointer",
          }}
          onClick={modalClose}
        >
          <img src={CancelTwo} alt="닫기" />
        </button>
        <Title>
          <HighLight>{limit}만원을 초과하는 결제</HighLight>는 <br />
          결제 대행사 정책상 <br />
          결제가 지원되지 않습니다.
        </Title>
        <Desc>금방금방 앱에서 안전하게 결제해주세요!</Desc>
        <ButtonWrapper>
          <StoreButton onClick={handleToStore("google")}>
            <img src={LogoGoogle} alt="logo_google" />
            <span>플레이스토어 바로가기</span>
          </StoreButton>
          <StoreButton onClick={handleToStore("apple")}>
            <img src={LogoApple} alt="logo_apple" />
            <span>앱스토어 바로가기</span>
          </StoreButton>
        </ButtonWrapper>
      </ModalBody>
    </Container>
  );
}

export default OverPriceModal;

const Container = styled.div`
  width: 100%;
  ${({ theme }) => theme.flexCenterColumn}
`;

const ModalBody = styled.div`
  background-color: white;
  width: 90%;
  height: auto;

  position: fixed;
  top: 47.5%;
  left: 50%;
  z-index: 1020;
  transform: translate(-50%, -50%);

  border-radius: 16px;
  padding: 16px 16px 24px;

  ${({ theme }) => theme.flexBox("column", "flex-start", "center")}

  @media only screen and (min-width: 900px) {
    align-self: center;
    min-width: auto;
    width: 432px;
  }
`;

const ModalBackdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  cursor: pointer;

  inset: 0;
  background-color: black;
  opacity: 0.54;
  z-index: 1010;
`;

const ErrorIcon = styled.img`
  width: 48px;
  height: 48px;
  margin-top: 8px;
`;

const Title = styled.div`
  color: ${colors.black200};
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  font-size: 18px;
  margin-top: 19px;
  text-align: center;
`;

const HighLight = styled.span`
  color: #ffa700;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
`;

const Desc = styled.div`
  color: #666;
  font-size: 16px;
  line-height: 28px;
  margin-top: 19px;
`;

const ButtonWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  gap: 8px;
`;
const StoreButton = styled.button`
  border-radius: 8px;
  border: 1px solid ${colors.black200};
  background: rgba(255, 255, 255, 0);
  width: 100%;
  display: flex;
  height: 48px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
`;
