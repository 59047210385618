import React, { useMemo } from "react";
import styled from "styled-components";
import colors from "assets/styles/color";
import { useDispatch } from "react-redux";

import ShareURL from "./ShareURL";
import { CustomModal } from "components";
import { useModal } from "hooks/useModal";
import Share from "assets/icons/share.webp";
import { openBottomSheet } from "features/bottomsheet/bottomsheetSlice";
import { fontWeight } from "assets/styles/fonts";

export default function PurchaseButton({
  isSoldOut,
  sellingPrice,
  standardPrice,
  goodsImage,
  goodsName,
  discountRate,
}) {
  const [modalVisible, modalOpen, modalClose] = useModal();
  const dispatch = useDispatch();
  const percent = discountRate > 0 ? discountRate : "";

  const handleOpenBottomSheet = () => {
    !isSoldOut && dispatch(openBottomSheet());
  };

  return (
    <>
      <CustomModal modalVisible={modalVisible} modalClose={modalClose}>
        <ShareURL
          SellingPrice={sellingPrice}
          StandardPrice={standardPrice}
          Discount={percent}
          GoodsImage={goodsImage}
          GoodsName={goodsName}
          modalClose={modalClose}
        />
      </CustomModal>
      <PurchaseButtonWrapper>
        <ShareBtn onClick={() => modalOpen()}>
          <img src={Share} alt="공유아이콘" />
        </ShareBtn>
        <PurchaseBtn
          className={isSoldOut && "isSoldOut"}
          onClick={handleOpenBottomSheet}
        >
          {isSoldOut ? "품절되었습니다" : "구매하기"}
        </PurchaseBtn>
      </PurchaseButtonWrapper>
    </>
  );
}

const PurchaseButtonWrapper = styled.div`
  width: 100%;
  padding: 4px 16px 16px;
  position: fixed;
  bottom: 0;
  z-index: 30;

  ${({ theme }) => theme.flexCenter}
  background-color: white;

  @media only screen and (min-width: 900px) {
    align-self: center;
    min-width: auto;
    width: 480px;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-color: #eaeaea;
  }
  &:after {
    content: "";
    position: absolute;
    left: 55%;
    top: -10%;
    transform: translate(-50%, -60%);
    width: 110%;
    height: 35%;
  }
`;

const PurchaseBtn = styled.button`
  width: 100%;
  height: 48px;

  border-radius: 8px;
  cursor: pointer;
  color: ${colors.white};
  font-weight: ${fontWeight.bold};
  background-color: ${colors.primaryOrange};
  font-size: 17px;

  &.isSoldOut {
    background-color: ${colors.gray};
    color: ${colors.disabledColr};
  }

  &:after {
    content: "";
    position: absolute;
    left: 55%;
    top: 50%;
    transform: translate(-50%, -60%);
    width: 90%;
    height: 120%;
  }
`;

const ShareBtn = styled.button`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid ${colors.imageBorder};
  cursor: pointer;
  margin-right: 8px;
  flex-grow: 1;

  img {
    width: 48px;
    height: 48px;
  }
`;
