import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import rightArrow from "assets/icons/right-arrow-gray.webp";
import { selectBuyInfo, setBuyInfo } from "features/buy/buySlice";
import { getHours } from "services/apis";
import { dateToString } from "utils/utils";
import {
  ArrowIcon,
  Button,
  DayCol,
  DisabledButton,
  FlexDiv,
  MonthBox,
  WeekRow,
} from "./Calendar.style";

const DAYS_OF_WEEK = ["일", "월", "화", "수", "목", "금", "토"];

const buildCalendar = (value) => {
  const startDay = value.clone().startOf("month").startOf("week");
  const endDay = value.clone().endOf("month").endOf("week");
  const month = value.month();
  const day = startDay.clone().subtract(1, "day");
  const calendar = [];
  while (day.isBefore(endDay, "day")) {
    calendar.push(
      Array(7)
        .fill(0)
        .map(() => {
          const date = day.add(1, "day").clone();
          return date.month() === month ? date : ""; // 이번 달 아니면 제외
        }),
    );
  }
  return calendar;
};

// TODO: 추후 앱과 몰 캘린더 로직 통일 시, 수정 필요
const buildCalendarWithWorkingDays = (dateValue, apiResponse) => {
  const { businessHoursList, holidaysList } = apiResponse;

  // 기본값: 월~금은 일한다고 간주
  const DEFAULT_WORKING_DAYS = ["0", "1", "2", "3", "4", "5", "6"];

  const workingDays = new Set(
    businessHoursList.length > 0
      ? businessHoursList.map((day) => day.dayWeek)
      : DEFAULT_WORKING_DAYS,
  );
  const value = moment(dateValue);

  const startDay = value.clone().startOf("month").startOf("week");
  const endDay = value.clone().endOf("month").endOf("week");
  const calendar = [];

  let day = startDay.clone().subtract(1, "day");
  while (day.isBefore(endDay, "day")) {
    calendar.push(
      Array(7)
        .fill(0)
        .map(() => {
          const date = day.add(1, "day").clone();
          if (date.month() !== value.month()) return ""; // 이번 달이 아니면 제외

          const dayWeek = date.isoWeekday() - 1;
          const holiday = holidaysList.find(
            (h) => h.manageDate === date.format("YYYY-MM-DD"),
          );

          return {
            date, // moment 객체 그대로 반환
            isFullDayOff: holiday
              ? holiday.isFullDayOff
              : !workingDays.has(dayWeek.toString()),
            dayWeek,
          };
        }),
    );
  }
  return calendar;
};

/** 다음 영업일 찾기 */
function findNextWorkingDay(currentDate, days) {
  for (const day of days) {
    if (
      day &&
      dateToString(day.date.toDate()) > currentDate &&
      day.isFullDayOff === false
    ) {
      return day;
    }
  }
  return undefined;
}

const useGetShopHours = (Month) => {
  const { BuyInfo } = useSelector(selectBuyInfo);
  const { shopId, goodsId } = BuyInfo;

  const { data: shopHours, isLoading } = useQuery(
    ["shopHours", shopId, Month.format("YYYY-MM")],
    () => getHours(goodsId, shopId, Month.format("YYYY-MM")),
    {
      select: (data) => data?.data?.data,
    },
  );
  return { shopHours, isLoading };
};

function Calendar() {
  const dispatch = useDispatch();
  const { BuyInfo } = useSelector(selectBuyInfo);
  const { visitDate, earliestVisitDate } = BuyInfo;

  const [selectedDay, setSelectedDay] = useState(() => {
    return new Date(earliestVisitDate);
  });

  const [calendar, setCalendar] = useState(
    buildCalendar(moment(earliestVisitDate)),
  );

  // 대리점 선택 후 바로 하단 예약확인 값 반영
  useEffect(() => {
    // if (visitDate === "") {
    dispatch(setBuyInfo({ visitDate: dateToString(selectedDay) }));
    // }
  }, [dispatch, selectedDay]);

  // 택배 및 대리점간 정보 이동 시 대리점 선택 정보 기억
  useEffect(() => {
    if (visitDate !== "") {
      setCalendar(buildCalendar(moment(visitDate)));
      saveInfo(moment(visitDate));
    }
  }, []);

  const Month = calendar[1][0];
  const isNextMonthDisabled =
    moment(Month).month() === moment(earliestVisitDate).add(1, "month").month();

  const { shopHours, isLoading } = useGetShopHours(Month);

  useEffect(() => {
    if (shopHours) {
      const holidayInfoCalendar = buildCalendarWithWorkingDays(
        selectedDay,
        shopHours,
      ).flat();

      // 현재 날짜가 휴일인지 확인
      const matchedDaysOff = holidayInfoCalendar.find((workingDay) => {
        if (workingDay.date) {
          return (
            dateToString(workingDay.date.toDate()) ===
              dateToString(selectedDay) && workingDay.isFullDayOff
          );
        }
        return (
          workingDay.date === dateToString(selectedDay) &&
          workingDay.isFullDayOff
        );
      });

      if (matchedDaysOff) {
        const nextWorkingDay = findNextWorkingDay(
          dateToString(matchedDaysOff.date.toDate()),
          holidayInfoCalendar,
        );
        if (nextWorkingDay) {
          setSelectedDay(nextWorkingDay.date.toDate());
        } else {
          setSelectedDay(moment(selectedDay).add(1, "months").date(1).toDate());
          setCalendar(
            buildCalendar(moment(selectedDay).add(1, "months").date(1)),
          );
        }
      }
    }
  }, [selectedDay, shopHours]);

  const prevMonth = () => {
    setCalendar(buildCalendar(moment(Month).subtract(1, "M")));
  };

  const nextMonth = () => {
    setCalendar(buildCalendar(moment(Month).add(1, "M")));
  };

  const getWeekOfMonth = (day) => {
    if (!day) return;
    const startOfMonth = day.clone().startOf("month");
    return day.clone().week() - startOfMonth.week();
  };

  const saveInfo = (day) => {
    setSelectedDay(day);
    dispatch(setBuyInfo({ visitDate: day.format("YYYY-MM-DD") }));
    const selectedDayWeek = getWeekOfMonth(day);

    if (!isLoading && shopHours?.businessHoursList.length !== 0) {
      for (let i = 0; i < shopHours?.businessHoursList.length; i++) {
        const businessHours = shopHours.businessHoursList[i];
        if (businessHours.dayWeek === selectedDayWeek?.toString()) {
          dispatch(
            setBuyInfo({
              openHr: businessHours.openHr,
              closeHr: businessHours.closeHr,
            }),
          );
          break;
        }
      }
    }
  };

  const handleClickDay = (day) => {
    if (
      day &&
      !isHoliday(day, shopHours.holidaysList) &&
      !day.isBefore(moment(earliestVisitDate), "day")
    ) {
      const selectedDayWeek = day.day();
      const isFullDayOff = shopHours.businessHoursList.some((businessHours) => {
        return (
          businessHours.dayWeek === selectedDayWeek.toString() &&
          businessHours.isFullDayOff
        );
      });
      if (!isFullDayOff) {
        saveInfo(day);
      }
    }
  };
  const isHoliday = (day, holidays) => {
    if (!holidays) {
      return false;
    }
    const formattedDay = day.format("YYYY-MM-DD");
    return holidays.some((holiday) => holiday.manageDate === formattedDay);
  };

  return (
    <div>
      <FlexDiv>
        {moment(earliestVisitDate).isBefore(moment(Month)) ? (
          <Button onClick={prevMonth}>
            <ArrowIcon className="left" src={rightArrow} alt="rightArrow" />
          </Button>
        ) : (
          <DisabledButton />
        )}
        <MonthBox>{Month?.format("YYYY년 MM월")}</MonthBox>
        {isNextMonthDisabled ? (
          <DisabledButton />
        ) : (
          <Button onClick={nextMonth}>
            <ArrowIcon src={rightArrow} alt="rightArrow" />
          </Button>
        )}
      </FlexDiv>
      <WeekRow>
        {DAYS_OF_WEEK.map((day) => (
          <DayCol key={day}>{day}</DayCol>
        ))}
      </WeekRow>
      {calendar.map((week, index) => (
        <WeekRow key={`week-${index}`}>
          {week.map((day, index) => {
            const selectedDayWeek = day && day.day();

            const isFullDayOff =
              shopHours?.businessHoursList.length === 0
                ? false
                : !shopHours?.businessHoursList
                    .map((businessHours) => businessHours.dayWeek)
                    .includes(selectedDayWeek?.toString());

            return (
              <DayCol
                key={day ? day.toString() : `empty-${index}`}
                onClick={() => handleClickDay(day)}
                isSelected={
                  day && selectedDay && day.isSame(selectedDay, "day")
                }
                isBefore={day && day.isBefore(moment(earliestVisitDate), "day")}
                isHoliday={
                  day && shopHours && isHoliday(day, shopHours.holidaysList)
                }
                isFullDayOff={isFullDayOff}
              >
                {day ? day.format("D") : ""}
              </DayCol>
            );
          })}
        </WeekRow>
      ))}
    </div>
  );
}

export default Calendar;
