import { useState, useEffect } from "react";
import styled from "styled-components";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import colors from "assets/styles/color";
import { getPreApproval } from "services/apis";
import { selectBuyInfo, setBuyInfo } from "features/buy/buySlice";
import { getSessionStorageItem } from "services/storageService";
import { useGetPrePriceByZero } from "pages/goods-detail/components/DetailBottomSheet";

import optionOpen from "assets/icons/option-open.webp";
import { NumberFormat, objToQueryString } from "utils/utils";

export const useGetPrePrice = () => {
  const { id } = useParams();
  const { BuyInfo } = useSelector(selectBuyInfo);
  const qty = getSessionStorageItem("qty");

  const { data: PrePriceData, isLoading } = useQuery(
    ["pre-price", id, qty, BuyInfo.useGram],
    () =>
      getPreApproval(
        objToQueryString({
          goodsId: id,
          qty: qty,
          useGram: BuyInfo?.useGram,
        }),
      ),
    { select: (data) => data?.data?.data },
  );
  return { PrePriceData, isLoading };
};

function EstimatedAmount() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const { BuyInfo } = useSelector(selectBuyInfo);
  const { buyPoint, buyKrw, useGram } = BuyInfo;
  const { PrePriceData } = useGetPrePrice();
  const { PrePriceByZero } = useGetPrePriceByZero();
  const { price, buyPrice } = PrePriceData || {};
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  // 현금과 포인트 순차적으로 차감하기
  const calculatedPoint = () => {
    return price - buyPoint > 0 ? buyPoint : price;
  }; // 포인트 뺄 금액
  const calculatedKrw = () => {
    const MinusPoint = calculatedPoint();
    const Difference = price - MinusPoint;
    return Difference - buyKrw > 0 ? buyKrw : Difference;
  }; // 포인트 빼고 남은 금액
  // 실제 계좌에서 차감할 금액
  const calculateTotalPrice = () => {
    return price - calculatedPoint() - calculatedKrw();
  };
  const usePoint = calculatedPoint();
  const useKrw = calculatedKrw();
  const totalPrice = calculateTotalPrice();

  useEffect(() => {
    dispatch(
      setBuyInfo({
        usePoint: usePoint,
        useKrw: useKrw,
        sellingPrice: PrePriceByZero?.price,
        depositPrice: totalPrice,
        buyPrice: buyPrice,
      }),
    );
  }, [usePoint, useKrw, totalPrice, PrePriceByZero, buyPrice]);

  return (
    <Container>
      <Header isOpen={isOpen} onClick={handleOpen}>
        결제예정 금액
        <OptionIcon>
          <img
            className={isOpen ? "isRotated" : "inverse"}
            src={optionOpen}
            alt="아이콘"
          />
        </OptionIcon>
      </Header>

      <Content className={isOpen ? "open" : "close"} isOpen={isOpen}>
        <ContentWrapper>
          <List>
            주문 금액{" "}
            <BoldText>{NumberFormat(PrePriceByZero?.price)} 원</BoldText>
          </List>
          <List>
            포인트 적용
            <GrayText>- {NumberFormat(usePoint)} 원</GrayText>
          </List>
          <List>
            보유현금 차감
            <GrayText>- {NumberFormat(useKrw)} 원</GrayText>
          </List>
          <List>
            보유자산 차감
            <GrayText className="pr-3">- {NumberFormat(useGram)} g</GrayText>
          </List>
          <List>
            배송비
            <GrayText>무료</GrayText>
          </List>
        </ContentWrapper>
      </Content>
      <Footer>
        금액
        <div>
          <Price>{NumberFormat(totalPrice)}</Price>
          <Won>원</Won>
        </div>
      </Footer>
    </Container>
  );
}

export default EstimatedAmount;

const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: white;
  border: 1px solid ${colors.purchaseBorder};

  margin: 16px 0;
`;

const Header = styled.div`
  width: 100%;
  height: 44px;
  padding: 10px 16px;

  border-radius: ${(props) => (props.isOpen ? "8px 8px 0 0" : "8px")};

  color: ${colors.black300};
  font-size: 17px;
  font-weight: 700;
  letter-spacing: -0.34px;
  ${({ theme }) => theme.flexBox("row", "space-between", "center")}
`;

const OptionIcon = styled.button`
  width: 36px;
  height: 36px;
  img {
    margin-right: -16px;
    width: 100%;
    height: 100%;

    &.isRotated {
      transform: rotate(180deg);
      transition: 0.3s;
    }

    &.inverse {
      transform: rotate(0deg);
      transition: 0.3s;
    }
  }
`;

const Content = styled.div`
  width: 100%;
  border-radius: 0 0 8px 8px;
  max-height: 0;
  overflow: hidden;
  border-top: 0px solid ${colors.purchaseBorder};
  transition: max-height ease-in-out 0.2s, border-top ease-in-out 0.2s;

  &.open {
    max-height: 500px;
    border-top: 1px solid ${colors.purchaseBorder};
  }
`;

const Footer = styled.div`
  width: 100%;
  height: 48px;

  background-color: RGB(255, 110, 14, 0.1);
  border-top: 1px solid ${colors.purchaseBorder};
  padding: 16px;
  ${({ theme }) => theme.flexBox("row", "space-between", "center")}

  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.32px;
  color: ${colors.black400};
`;

const ContentWrapper = styled.div`
  padding: 16px;
`;

const Price = styled.span`
  font-size: 18px;
  font-weight: 700;
  font-family: "SpoqaHanSansNeo";
  color: ${colors.black400};
`;

const Won = styled.span`
  color: ${colors.black400};
  font-size: 16px;
  font-weight: 400;
  font-family: "SpoqaHanSansNeo";
`;

const List = styled.div`
  font-size: 15px;
  letter-spacing: -0.3px;
  color: ${colors.black400};
  margin-bottom: 10px;
  ${({ theme }) => theme.flexBox("row", "space-between", "center")}
`;

const GrayText = styled.span`
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.26px;
  color: ${colors.black900};

  &.pr-3 {
    padding-right: 3px;
  }
`;

const BoldText = styled.span`
  font-size: 15px;
  font-family: "SpoqaHanSansNeo";
  font-weight: 700;
  color: ${colors.black300};
`;
