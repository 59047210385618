import colors from "assets/styles/color";
import styled from "styled-components";

export const FlexDiv = styled.div`
  ${({ theme }) => theme.flexCenter};
`;

export const WeekRow = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const DayCol = styled.button`
  text-align: center;
  font-family: "SpoqaHanSansNeo";
  font-size: 14px;
  letter-spacing: -0.28px;
  font-weight: ${(props) => (props.isSelected ? 700 : 500)};
  color: ${(props) =>
    props.isHoliday || props.isBefore || props.isFullDayOff // 오늘 이전이거나, 휴무일이거나, 비활성화된 경우
      ? "#dddddd"
      : props.isSelected // 선택된 날이면
      ? "white"
      : colors.black300}; // 방문 가능한 날이면
  background-color: ${(props) =>
    props.isSelected && !props.isHoliday && !props.isFullDayOff
      ? "#ff6e0e"
      : "transparent"};
  border-radius: ${(props) => (props.isSelected ? "50%" : "")};
  width: 40px;
  ${({ theme }) => theme.flexCenter};
  height: 40px;
  padding: 12px;
  flex-shrink: 0;
  /* 선택 가능한 날만 pointer */
  cursor: ${({ isBefore, isHoliday, isFullDayOff }) =>
    !isHoliday && !isBefore && !isFullDayOff ? "pointer" : "auto"};
`;

export const Button = styled.button`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #f2f2f2;
  ${({ theme }) => theme.flexCenter};
`;

export const ArrowIcon = styled.img`
  width: 4.5px;
  height: 9px;
  &.left {
    transform: rotate(180deg);
  }
`;

export const MonthBox = styled.div`
  margin: 0 14px;
`;

export const DisabledButton = styled(Button)`
  background-color: transparent;
  cursor: default;
`;
